export function Reproved(props: any) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect width={24} height={24} rx={12} fill="#CF2860" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.73 7.576a.923.923 0 10-1.306-1.306L12 10.695 7.576 6.27A.923.923 0 006.27 7.576L10.695 12 6.27 16.424a.923.923 0 101.306 1.306L12 13.305l4.424 4.425a.923.923 0 101.306-1.306L13.305 12l4.425-4.424z"
                fill="#fff"
            />
        </svg>
    );
}

// export type PrescriptionType = "Pendentes" | "Reprovadas" | "Validadas" | "Todas";
export enum PrescriptionType {
  Pendentes = "Pendentes",
  Reprovadas = "Reprovadas",
  Validadas = "Validadas",
  Todas = "Todas",
}

export enum PrescriptionStatus {
  analisypending = "AnalisyPending",
  reproved = "Reproved",
  approved = "Approved",
  all = "",
}

export enum PrescriptionStatusToFilter {
  Pendentes = "AnalisyPending",
  Reprovadas = "Reproved",
  Validadas = "Approved",
  Todas = "",
}


export const PrescriptionStatusMapping = {
  [PrescriptionStatus.analisypending]: "Pendentes",
  [PrescriptionStatus.reproved]: "Reprovadas",
  [PrescriptionStatus.approved]: "Validadas",
  [PrescriptionStatus.all]: "Todas",
} as const;


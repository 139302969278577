import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_PRESCRIPTION_URL;

const api = axios.create({
    baseURL: BASE_URL,
});

const VIDALINK_SESSION_ID = process.env.REACT_APP_VIDALINK_SESSION_ID;

api.interceptors.request.use((config) => {
    try {
        const token = localStorage.getItem("accessToken");

        if (config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers["Vidalink-Session-Id"] = VIDALINK_SESSION_ID;
        }

        return config;
    } catch (err) {
        throw Error("No token");
    }
});

export function verifyAnalysisStatus({ username, objectId }) {
    return api.get(
        `${BASE_URL}/prescription/Previous-Shipment/iteration/lastanalysestatus?username=${username}&objectId=${objectId}`
    );
}

export function handleAnalysisStatus(body) {
    return api.patch(
        `${BASE_URL}/prescription/Previous-Shipment/iteration/analysestatushistory`,
        body
    );
}

import { useEffect } from "react";
import { PrivatePage } from "components/layouts/PrivatePage";
import { PrescriptionsNavbar } from "components/widgets/molecules/PrescriptionsNavbar";
import { TableList } from "components/views/PrescriptionList/TableList";

export function PrescriptionList() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    return (
        <PrivatePage>
            <PrescriptionsNavbar />
            <TableList />
        </PrivatePage>
    );
}

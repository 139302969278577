import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "components/widgets/organisms/Header";
import { useAppSelector } from "hooks/store";

type PublicPageProps = {
    children: JSX.Element | JSX.Element[];
};

export function PublicPage({ children }: PublicPageProps) {
    const navigate = useNavigate();

    const isUserAuthenticated = useAppSelector((state) => state.login.userAuthenticated);

    useEffect(() => {
        if (isUserAuthenticated) {
            navigate("/");
        }
    }, [isUserAuthenticated, navigate]);

    return (
        <div className="flex h-screen flex-col">
            <Header />
            {children}
        </div>
    );
}

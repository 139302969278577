import { MdWarning } from "react-icons/md";
import { AiFillInfoCircle } from "react-icons/ai";
import { toast } from "react-hot-toast";

export enum ToastType {
    Error,
    Warning,
}

type ToastProps = {
    t: any;
    text: string;
    type: ToastType;
};

export function Toast({ t, text, type }: ToastProps) {
    const { id } = t;

    function getTypeClass() {
        switch (type) {
            case ToastType.Error:
                return "bg-red-500";
            case ToastType.Warning:
                return "bg-amber-500";
        }
    }

    function handleClose(toastId: string) {
        toast.dismiss(toastId);
    }

    return (
        <div
            className={`${
                t.visible ? "opacity-100" : "opacity-0"
            } pointer-events-auto relative flex w-full items-center justify-center gap-2 p-4 text-white shadow-lg transition ${getTypeClass()}`}
        >
            {type === ToastType.Warning && <MdWarning />}
            {type === ToastType.Error && <AiFillInfoCircle />}
            <span className="leading-5">{text}</span>

            <button
                type="button"
                onClick={() => handleClose(id)}
                className="absolute right-8 top-0 bottom-0 my-auto h-10 w-10 rounded-full text-3xl transition hover:bg-gray-500 hover:bg-opacity-50"
            >
                &times;
            </button>
        </div>
    );
}

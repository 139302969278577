export function Success(props: any) {
    return (
        <svg width={149} height={149} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width={149} height={149} rx={74.5} fill="#3BA648" />
            <path
                d="M107 54 62.312 99 42 78.546"
                stroke="#fff"
                strokeWidth={14}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

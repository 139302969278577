import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export type Product = {
    uuid?: number;
    classification: {};
    attributes: any;
    validDate: Boolean;
    productStatus: any;
    name: string;
    ean: string;
    idProductStatus: number;
    dosage: number;
    isApprovedSimilarProduct?: boolean;
    isSingularProduct?: boolean;
    status?: string;
    eanReference?: number;
};

type ReprovalInfoType = {
    idReprovalReason: number;
    idReprovalReasons: Array<number>;
    reprovalObservation: string;
    reprovalReasonDescription: string;
    reprovalReasonsDescriptions: Array<string>;
};

type INITIAL_STATE = {
    data: {
        protocol: string;
        username: string;
        cardNumber: string;
        socialId: string;
        customerId: string;
        clientId: string;
        clientGroup: string;
        stateRegionalCouncil: string;
        regionalCouncil: number;
        regionalCouncilType: number;
        prescriptionDate: string | null;
        prescriptionName: string;
        files: Array<{ fileName: "" }>;
        products: Array<any>;
        approvedProducts: Array<Product>;
        refusedProducts: Array<Product>;
        status: {
            dateStatus: string;
            description: string;
            idStatus: number;
            reprovalInformation: ReprovalInfoType;
            status: string;
        };
    };
    approvedProducts: Array<Product>;
    approvedSimilarProducts: Array<Product>;
    reprovalReasonsIds: Array<string>;
    reprovalReasonDescription: string;
    prescriptionDate: string;
    stateRegionalCouncil: string;
    regionalCouncil: number;
    councilType: number;
    loadingDetailPage: boolean;
};

const initialState: INITIAL_STATE = {
    data: {
        protocol: "",
        username: "",
        cardNumber: "",
        customerId: "",
        socialId: "",
        stateRegionalCouncil: "",
        regionalCouncil: 0,
        regionalCouncilType: 0,
        clientGroup: "",
        clientId: "",
        prescriptionDate: "",
        prescriptionName: "",
        files: [],
        products: [],
        approvedProducts: [],
        refusedProducts: [],
        status: {
            dateStatus: "",
            description: "",
            idStatus: 0,
            reprovalInformation: {
                idReprovalReason: 0,
                idReprovalReasons: [],
                reprovalObservation: "",
                reprovalReasonDescription: "",
                reprovalReasonsDescriptions: [],
            },
            status: "",
        },
    },
    approvedProducts: [],
    approvedSimilarProducts: [],
    reprovalReasonsIds: [],
    reprovalReasonDescription: "",
    prescriptionDate: "",
    regionalCouncil: 0,
    stateRegionalCouncil: "",
    councilType: 0,
    loadingDetailPage: false,
};

export const PrescriptionDetailSlice = createSlice({
    name: "prescriptionDetail",
    initialState,
    reducers: {
        setDetails: (state, action: PayloadAction<INITIAL_STATE>) => {
            return {
                ...state,
                data: action.payload.data,
            };
        },
        resetDetails: (state) => {
            return initialState;
        },
        updateStateRegionalCouncil(state, action: PayloadAction<string>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    stateRegionalCouncil: action.payload,
                },
            };
        },
        updateRegionalCouncil(state, action: PayloadAction<number>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    regionalCouncil: action.payload,
                },
            };
        },
        updateTypeCouncil(state, action: PayloadAction<number>) {
            return {
                ...state,
                data: {
                    ...state.data,
                },
                councilType: action.payload,
            };
        },
        updatePrescriptionDate(state, action: PayloadAction<string | null>) {
            if (!action.payload) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                    },
                };
            }

            const prescriptionDate = new Date(action.payload).toISOString();

            return {
                ...state,
                data: {
                    ...state.data,
                    prescriptionDate,
                },
            };
        },
        updatePatient: (state, action: PayloadAction<any>) => {
            const { name, cardNumber } = action.payload;

            return {
                ...state,
                data: {
                    ...state.data,
                    username: name,
                    cardNumber: cardNumber,
                },
            };
        },
        addApprovedProduct: (state, action: PayloadAction<Product[]>) => {
            return {
                ...state,
                approvedProducts: [...state.approvedProducts, ...action.payload],
            };
        },
        addApprovedSimilarProduct: (state, action: PayloadAction<Product>) => {
            state.approvedSimilarProducts.push(action.payload);
        },
        removeApprovedProductByUUID: (state, action) => {
            return {
                ...state,
                approvedProducts: state.approvedProducts.filter(
                    (item) => item.uuid !== action.payload
                ),
            };
        },
        removeApprovedProductByEan: (state, action) => {
            return {
                ...state,
                approvedProducts: state.approvedProducts.filter(
                    (item) => item.ean !== action.payload
                ),
            };
        },
        resetApprovedProducts: (state) => {
            return {
                ...state,
                approvedProducts: [],
            };
        },
        setReprovalReasonDescription(state, action: PayloadAction<string>) {
            return {
                ...state,
                reprovalReasonDescription: action.payload,
            };
        },
        setReprovalReasons: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                reprovalReasonsIds: action.payload,
            };
        },
        resetReprovalReasons: (state) => {
            return {
                ...state,
                reprovalReasonsIds: [],
            };
        },
        setLoadingDetailPageOn: (state) => {
            return { ...state, loadingDetailPage: true };
        },
        setLoadingDetailPageOff: (state) => {
            return { ...state, loadingDetailPage: false };
        },
    },
});

export const {
    setDetails,
    resetDetails,
    updateStateRegionalCouncil,
    updateRegionalCouncil,
    updateTypeCouncil,
    updatePrescriptionDate,
    updatePatient,
    addApprovedProduct,
    addApprovedSimilarProduct,
    removeApprovedProductByEan,
    removeApprovedProductByUUID,
    resetApprovedProducts,
    resetReprovalReasons,
    setReprovalReasons,
    setReprovalReasonDescription,
    setLoadingDetailPageOn,
    setLoadingDetailPageOff,
} = PrescriptionDetailSlice.actions;

export const selectPrescriptionDetail = (state: RootState) => state.prescriptions;
export const isPrescriptionPending = (state: RootState) =>
    state.prescriptionDetail.data.status.status === "AnalisyPending";

export default PrescriptionDetailSlice.reducer;

import { ChangeEvent, useEffect } from "react";

import ArrowLeft from "assets/icons/ArrowLeft";
import ArrowRight from "assets/icons/ArrowRight";

import useFilterPrescriptions from "../../../../store/modules/filterPrescriptions";

export function Footer({
    rowCount,
    page,
    rowsPerPage,
    changeRowsPerPage,
    changePage,
    saveRowQuantity,
}) {
    const { filterPrescriptions, setFilterPrescriptions } = useFilterPrescriptions();

    const totalPages = filterPrescriptions.pagination.totalPages ?? 1;

    const hasPreviousPage =
        filterPrescriptions.currentPage <= totalPages && filterPrescriptions.currentPage > 1;

    const hasNextPage = filterPrescriptions.currentPage < totalPages;

    function handleChange(event: ChangeEvent<HTMLSelectElement>) {
        const { value } = event.target;
        saveRowQuantity(parseInt(value));
    }

    function handleGoPreviousPage() {
        if (hasPreviousPage) {
            setFilterPrescriptions({
                currentPage: filterPrescriptions.currentPage - 1,
            });
        }
    }

    function handleGoNextPage() {
        if (hasNextPage) {
            setFilterPrescriptions({
                currentPage: filterPrescriptions.currentPage + 1,
            });
        }
    }

    return (
        <div data-testid="table-footer" className="mt-12 flex items-center justify-between">
            <p className="font-bold text-vidalink-blue">Copyright © Vidalink do Brasil S/A</p>
            <div className="flex items-center">
                <p className="mr-5 font-bold text-vidalink-gray-700">Quantidade de linhas</p>
                <select
                    onChange={handleChange}
                    className="w-[76px] bg-none px-2 text-center text-xl font-bold"
                >
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>

                <hr className="mx-5 h-10 border border-vidalink-gray-700" />

                <div className="flex w-32 items-center justify-center gap-2">
                    <button
                        onClick={handleGoPreviousPage}
                        disabled={!hasPreviousPage}
                        className="disabled:opacity-50"
                    >
                        <ArrowLeft />
                    </button>
                    <p className=" font-bold">
                        {filterPrescriptions.currentPage} de {totalPages}
                    </p>
                    <button
                        onClick={handleGoNextPage}
                        disabled={!hasNextPage}
                        className="disabled:opacity-50"
                    >
                        <ArrowRight />
                    </button>
                </div>
            </div>
        </div>
    );
}

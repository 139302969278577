const Trash = (props) => (
    <svg width={20} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.293 2.293A1 1 0 0 1 8 2h4a1 1 0 0 1 1 1v1H7V3a1 1 0 0 1 .293-.707ZM5 4V3a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1h4a1 1 0 1 1 0 2h-1v13a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6H1a1 1 0 0 1 0-2h4ZM4 6h12v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6Z"
            fill="#fff"
        />
    </svg>
);

export default Trash;

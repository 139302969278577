import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import AlertCircleIcon from "assets/icons/AlertCircleIcon";

export enum MessageType {
    VALIDATION = "validation",
    REJECTION = "rejection",
    MESSAGE = "message"
}

type PopupProps = {
    type: MessageType;
    isOpen: boolean;
    close: () => void;
};

export function AlertModal({ isOpen, close, type }: PopupProps) {
    const [text, setText] = useState("");

    useEffect(() => {
        switch (type) {
            case "message":
                setText("Não foi possível encontrar receitas com os campos digitados!");
                return;
        }
    }, [type]);

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog open={isOpen} onClose={close} className="relative z-50 flex justify-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-1"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-1"
                    leaveFrom="opacity-10"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-05"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-05"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div
                        data-testid="success-popup"
                        className="fixed inset-0 m-auto flex h-[409px] w-[732px] flex-col items-center justify-between rounded-lg bg-white p-4 py-20"
                    >
                        <Dialog.Panel className="flex h-full flex-col items-center justify-between rounded bg-white">
                            <AlertCircleIcon />

                            <p className="text-center text-[2rem] font-bold text-vidalink-blue">
                                {text}
                            </p>
                            <br />
                            <button
                                onClick={close}
                                className="block w-[200px] cursor-pointer rounded-3xl bg-vidalink-blue p-2 text-center font-bold leading-6 text-white transition hover:opacity-90"
                            >
                                Ok
                            </button>                            
                        </Dialog.Panel>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition>
    );
}

import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/store";
import LogoVidalink from "assets/icons/LogoVidalink";

import { logout } from "store/modules/login";

type HeaderProps = {
    username?: string;
};

export function Header({ username }: HeaderProps) {
    const dispatch = useAppDispatch();

    const isUserAuthenticated = useAppSelector((state) => state.login.userAuthenticated);

    function handleLogout() {
        dispatch(logout());
    }

    return (
        <header
            data-testid="app-header"
            className="flex h-16 items-center border-b-4 border-vidalink-blue px-5 py-8 print:hidden"
        >
            <div
                className={`container mx-auto flex items-center  gap-14 ${
                    isUserAuthenticated ? "justify-between" : "justify-center"
                }`}
            >
                <Link to="/">
                    <LogoVidalink className="w-44" />
                </Link>

                {isUserAuthenticated && (
                    <div className="flex flex-1 items-center justify-between">
                        <span
                            data-testid="user-greeting"
                            className="text-xl font-bold leading-4 text-vidalink-blue"
                        >
                            Olá, {username}!
                        </span>
                        <button
                            onClick={handleLogout}
                            className="w-56 rounded-3xl bg-vidalink-magenta p-3 font-bold text-white"
                        >
                            Sair
                        </button>
                    </div>
                )}
            </div>
        </header>
    );
}

import { useEffect, useState } from "react";
import { formatTime } from "utils/formatTime";

type TimerProps = {
    slaMinutes: number;
    currentMinutes: number;
    slaTimer?: string;
    clockRunning?: boolean;
};

enum Status {
    POSITIVE,
    NEGATIVE,
    YELLOW,
}

export function SLATimer({ currentMinutes, slaMinutes, slaTimer, clockRunning }: TimerProps) {
    const [status, setStatus] = useState<Status>(Status.NEGATIVE);
    const [clockTimeInSeconds, setClockTimeInSeconds] = useState<number>(0);
    const yellowRangeLimitInSeconds = slaMinutes * 0.1 * 60;

    function getStyleByStatus(status: Status) {
        switch (status) {
            case Status.NEGATIVE:
                return `text-vidalink-magenta`;
            case Status.POSITIVE:
                return `text-vidalink-green`;
            case Status.YELLOW:
                return `text-yellow-500`;
        }
    }

    function formatSla() {
        let formatedSla = formatTime(clockTimeInSeconds);

        if (formatedSla.includes(".")) {
            formatedSla = formatedSla.split(".")[0];
        }

        return formatedSla;
    }

    useEffect(() => {
        setClockTimeInSeconds(currentMinutes * 60);
    }, [currentMinutes]);

    useEffect(() => {
        setStatus(Status.NEGATIVE);

        if (clockTimeInSeconds > 0) {
            setStatus(Status.YELLOW);
        }
        if (clockTimeInSeconds > yellowRangeLimitInSeconds) {
            setStatus(Status.POSITIVE);
        }
    }, [clockTimeInSeconds, yellowRangeLimitInSeconds]);

    useEffect(() => {
        const interval = setInterval(() => {
            setClockTimeInSeconds(clockTimeInSeconds - 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [clockTimeInSeconds, currentMinutes]);

    return (
        <div
            className={`flex items-center justify-center gap-1 font-medium ${getStyleByStatus(
                status
            )}`}
        >
            <span className="text-xl font-bold leading-3">&middot;</span>
            {!clockRunning ? slaTimer : formatSla()}
        </div>
    );
}

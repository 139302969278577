import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "components/widgets/organisms/Header";
import { useAppSelector } from "hooks/store";

type PrivatePageProps = {
    children: JSX.Element | JSX.Element[];
};

export function PrivatePage({ children }: PrivatePageProps) {
    const navigate = useNavigate();
    const isUserAuthenticated = useAppSelector((state) => state.login.userAuthenticated);
    const { username } = useAppSelector((state) => state.login);

    useEffect(() => {
        if (!isUserAuthenticated) {
            navigate("/login");
        }
    }, [isUserAuthenticated, navigate]);

    return (
        <div>
            <Header username={username} />
            {children}
        </div>
    );
}

import { create } from "zustand";

export type FilterPrescriptionsType = {
    protocol: string;
    limitPages: number;
    currentPage: number;
    prescriptionStatus: "Pendentes" | "Todas" | "Reprovadas" | "Validadas";
    username: string;
    customerId: string;
    customerName: string;
    memberId: string;
    prescriptionReceivedDate: string;
    startAnalyseDateTime: string;
    userAnalyse: string;
    endAnalyseDateTime: string;
    pagination: {
        totalRecords?: number;
        totalPages?: number;
        currentPage?: number;
    };
};

type PrescriptionStore = {
    filterPrescriptions: FilterPrescriptionsType;
    setFilterPrescriptions: (newDetails: Partial<FilterPrescriptionsType>) => void;
};

const usePrescriptionStore = create<PrescriptionStore>((set) => ({
    filterPrescriptions: {
        protocol: "",
        currentPage: 1,
        limitPages: 20,
        prescriptionStatus: "Pendentes",
        username: "",
        customerId: "",
        customerName: "",
        memberId: "",
        prescriptionReceivedDate: "",
        startAnalyseDateTime: "",
        userAnalyse: "",
        endAnalyseDateTime: "",
        pagination: {
            currentPage: 0,
            totalPages: 0,
            totalRecords: 0,
        },
    },
    setFilterPrescriptions: (newFilterPrescriptions) =>
        set((state) => ({
            filterPrescriptions: { ...state.filterPrescriptions, ...newFilterPrescriptions },
        })),
}));

export default usePrescriptionStore;

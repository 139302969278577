import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { SubmitHandler, useForm } from "react-hook-form";

import DownloadIcon from "assets/icons/download.png";
import PrintIcon from "assets/icons/print.png";

import usePrescriptionStore, { FilterPrescriptionsType } from "store/modules/filterPrescriptions";
import { CSVLink } from "react-csv";
import moment from "moment";
import { format, parse, toDate } from "date-fns";

type SearchProps = {
    data: [];
};
const Search = ({ data }: SearchProps) => {
    const { setFilterPrescriptions } = usePrescriptionStore();

    const { register, handleSubmit, setValue } = useForm<Partial<FilterPrescriptionsType>>();

    const [dataToCsv, setDataToCsv] = useState([]);

    const onSubmit: SubmitHandler<Partial<FilterPrescriptionsType>> = (data) => {
        const formatDateString = (dateString) => {
            if (!dateString) return null;
            const parsedDate = parse(dateString, "dd/MM/yyyy", new Date());
            return format(toDate(new Date(parsedDate)), "yyyy/MM/dd");
        };

        const formattedPrescriptionDate = formatDateString(data.prescriptionReceivedDate);
        const formattedEndAnalyseDate = formatDateString(data.endAnalyseDateTime);

        const updatedData = { ...data, protocol: data.protocol?.trim() };

        if (formattedPrescriptionDate) {
            updatedData.prescriptionReceivedDate = formattedPrescriptionDate;
        }

        if (formattedEndAnalyseDate) {
            updatedData.endAnalyseDateTime = formattedEndAnalyseDate;
        }

        setFilterPrescriptions(updatedData);
    };

    useEffect(() => {
        if (data) {
            const dataToArray: any = data.map((item: any) => {
                return {
                    Protocolo: item.protocol,
                    "Member ID": item.cardNumber,
                    "Nome do Convenio": item.customerName,
                    "Id Convênio": item.customerId,
                    "Data e hora do recebimento": moment(item.prescriptionDate).format(
                        "DD/MM/YYYY HH:mm:ss"
                    ),
                    status: item.status.description,
                    "Usuário análise":
                        item?.analyseStatusHistory && item.analyseStatusHistory.length > 0
                            ? item.analyseStatusHistory[0].username
                            : "-",
                    "Data início análise": moment(item?.status?.startAnalyseDateTime).format(
                        "DD/MM/YYYY HH:mm:ss"
                    ),
                    "Data fim análise": moment(item?.status?.dateStatus).format(
                        "DD/MM/YYYY HH:mm:ss"
                    ),
                };
            });

            setDataToCsv(dataToArray);
        }
    }, [data]);

    const handleTrimProtocol = (e) => {
        const { name, value } = e.target;
        const trimmedValue = value.replace(/\s+/g, "");
        setValue(name, trimmedValue);
    };

    return (
        <div className="grid grid-cols-4 gap-y-10">
            <div className="flex flex-col items-center lg:w-[275px] xl:w-[305px] 2xl:w-[370px]">
                <label
                    htmlFor="username"
                    className="text-left text-xl font-bold text-vidalink-blue lg:w-[275px] xl:w-[305px] 2xl:w-[370px]"
                >
                    Nome da Vida
                </label>
                <input
                    {...register("username")}
                    type="text"
                    id="username"
                    className="w-full text-xl"
                    placeholder="Digite"
                    autoComplete="off"
                />
            </div>
            <div className=" flex flex-col items-center lg:w-[275px] xl:w-[305px] 2xl:w-[370px]">
                <label
                    htmlFor="memberId"
                    className="text-left text-xl font-bold text-vidalink-blue lg:w-[275px] xl:w-[305px] 2xl:w-[370px]"
                >
                    Member id
                </label>
                <input
                    {...register("memberId")}
                    type="text"
                    id="memberId"
                    className="w-full text-xl"
                    placeholder="Digite"
                    autoComplete="off"
                />
            </div>
            <div className=" flex flex-col items-center lg:w-[275px] xl:w-[305px] 2xl:w-[370px]">
                <label
                    htmlFor="customerName"
                    className="text-left text-xl font-bold text-vidalink-blue lg:w-[275px] xl:w-[305px] 2xl:w-[370px]"
                >
                    Nome do convênio
                </label>
                <input
                    {...register("customerName")}
                    type="customerName"
                    id=""
                    className="w-full text-xl"
                    placeholder="Digite"
                    autoComplete="off"
                />
            </div>
            <div className=" flex flex-col items-center lg:w-[275px] xl:w-[305px] 2xl:w-[370px] ">
                <label
                    htmlFor="protocol"
                    className="text-left text-xl font-bold text-vidalink-blue lg:w-[275px] xl:w-[305px] 2xl:w-[370px]"
                >
                    Protocolo
                </label>
                <input
                    {...register("protocol")}
                    type="text"
                    id="protocol"
                    className="w-full text-xl"
                    placeholder="Digite"
                    autoComplete="off"
                    onBlur={handleTrimProtocol}
                />
            </div>
            <div className=" flex flex-col items-center lg:w-[275px] xl:w-[305px] 2xl:w-[370px]">
                <label
                    htmlFor="customerId"
                    className="text-left text-xl font-bold text-vidalink-blue lg:w-[275px] xl:w-[305px] 2xl:w-[370px]"
                >
                    Customer id
                </label>
                <input
                    {...register("customerId")}
                    type="text"
                    id="customerId"
                    className="w-full text-xl"
                    placeholder="Digite"
                    autoComplete="off"
                />
            </div>
            <div className=" flex flex-col items-center lg:w-[275px] xl:w-[305px] 2xl:w-[370px]">
                <label
                    htmlFor="prescriptionReceivedDate"
                    className="text-left text-xl font-bold text-vidalink-blue lg:w-[275px] xl:w-[305px] 2xl:w-[370px]"
                >
                    Data de recebimento
                </label>
                <InputMask
                    mask="99/99/9999"
                    {...register("prescriptionReceivedDate")}
                    type="text"
                    id="prescriptionReceivedDate"
                    className="w-full text-xl"
                    placeholder="Digite"
                    autoComplete="off"
                />
            </div>
            <div className="flex flex-col items-center lg:w-[275px] xl:w-[305px] 2xl:w-[370px]">
                <label
                    htmlFor="endAnalyseDateTime"
                    className="text-left text-xl font-bold text-vidalink-blue lg:w-[275px] xl:w-[305px] 2xl:w-[370px]"
                >
                    Data fim da análise
                </label>
                <InputMask
                    mask="99/99/9999"
                    {...register("endAnalyseDateTime")}
                    type="text"
                    id="endAnalyseDateTime"
                    className="w-full text-xl"
                    placeholder="Digite"
                    autoComplete="off"
                />
            </div>

            <div className="flex items-end justify-center gap-4">
                <button
                    onClick={handleSubmit(onSubmit)}
                    className="block w-[200px] cursor-pointer rounded-3xl bg-vidalink-blue p-2 text-center font-bold leading-6 text-white transition hover:opacity-90"
                >
                    Buscar
                </button>
                <div className="flex max-w-[85px] gap-4">
                    <CSVLink data={dataToCsv} separator=";">
                        <img
                            width={68}
                            height={68}
                            className="cursor-pointer"
                            src={DownloadIcon}
                            alt=""
                        />
                    </CSVLink>
                    <img
                        onClick={() => window.print()}
                        width={40}
                        height={40}
                        className="cursor-pointer"
                        src={PrintIcon}
                        alt=""
                    />
                    {/*                     <img src={ColumnsIcon} alt="" />
                        <img src={FilterIcon} alt="" /> */}
                </div>
            </div>
        </div>
    );
};

export default Search;

import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { Card } from "components/widgets/organisms/Card";
import { isPrescriptionPending, updatePatient } from "store/modules/prescriptionDetail";

export type Beneficiary = {
    name: string;
    cardNumber: string;
};

type Props = {
    beneficiaries: Beneficiary[];
};

export function Patient({ beneficiaries }: Props) {
    const { data } = useAppSelector((state) => state.prescriptionDetail);
    const isPending = useAppSelector(isPrescriptionPending);

    const { protocol, cardNumber } = data;

    const [selectedCardNumber, setCardNumber] = useState<string>(cardNumber);

    const selectBeneficiary = useRef<HTMLSelectElement>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setCardNumber(cardNumber);

        const index = beneficiaries.findIndex(
            (beneficiary) => beneficiary.cardNumber === cardNumber
        );

        if (index >= 0 && selectBeneficiary.current) {
            selectBeneficiary.current.selectedIndex = index;
        }
    }, [beneficiaries, cardNumber]);

    function handleChangeBeneficiary(event: ChangeEvent<HTMLSelectElement>) {
        const selectedIndex = event.target.value;
        const selectedBeneficiary = beneficiaries[selectedIndex];

        dispatch(updatePatient(selectedBeneficiary));
        setCardNumber(selectedBeneficiary.cardNumber);
    }

    return (
        <Card>
            <h3 className="text-[2rem] font-bold text-vidalink-blue">Dados do paciente</h3>

            <div
                data-testid="patient-card"
                className="flex flex-col justify-between gap-4 xl:flex-row"
            >
                <div className="form-control flex-1">
                    <label htmlFor="protocol">Protocolo</label>
                    <input type="text" disabled value={protocol} />
                </div>

                <div className="form-control flex-1">
                    <label htmlFor="protocol">Beneficiário</label>
                    <select
                        disabled={!isPending}
                        onChange={handleChangeBeneficiary}
                        ref={selectBeneficiary}
                    >
                        {beneficiaries.map((beneficiary, index) => (
                            <option key={beneficiary.cardNumber} value={index}>
                                {beneficiary.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-control flex-1">
                    <label htmlFor="protocol">Número do cartão</label>
                    <input type="text" disabled value={selectedCardNumber} />
                </div>
            </div>
        </Card>
    );
}

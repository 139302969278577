import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_PRESCRIPTION_URL;
const VIDALINK_SESSION_ID = process.env.REACT_APP_VIDALINK_SESSION_ID;

const api = axios.create({
    baseURL: BASE_URL,
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            sessionStorage.clear();
            localStorage.clear();

            return (window.location.href = "/login");
        }
    }
);

api.interceptors.request.use((config) => {
    try {
        const token = localStorage.getItem("accessToken");

        if (config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers["Vidalink-Session-Id"] = VIDALINK_SESSION_ID;
        }

        return config;
    } catch (err) {
        throw Error("No token");
    }
});

type getDoctorNameBodyType = {
    number: number;
    state: string;
};

type DoctorNameReturnType = {
    message: string;
    diagnostic: any;
    data: {
        number: number;
        type: number;
        state: string;
        name: string;
    } | null;
    status: boolean;
    processedAt: string;
};

export function getDoctorName(params: getDoctorNameBodyType) {
    const { number, state } = params;

    const body = {
        number,
        state,
        type: 1,
    };

    return api.post<DoctorNameReturnType>("specialist/validate", body);
}

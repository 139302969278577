export function TableHead(props) {
    const { columns, onSort, order, setOrder } = props;

    const handleSort = (column, label) => {
        if (
            (column != "customerName" && column != "customerId" && column != "createdAt") ||
            label == "Data e Hora Recebimento"
        )
            return;

        let columnToFilter = column;

        if (columnToFilter === "createdAt") columnToFilter = "slaTime";
        const newOrder = order === "asc" ? "desc" : "asc";

        setOrder(newOrder);
        onSort(columnToFilter, newOrder, false);
    };

    return (
        <thead className="border-separate border-spacing-2">
            <tr>
                {columns?.map((column) => {
                    const { name, label, display, print, viewColumns } = column;
                    const hideColumn = display === "false" || viewColumns === "false";

                    if (hideColumn) return null;

                    return (
                        <th
                            key={`${name}-${label}-${display}`}
                            className={`w-40 ${!print ? "print:hidden" : ""}`}
                            style={{
                                cursor:
                                    column.label == "Nome do Convênio" ||
                                    column.label == "Id convênio" ||
                                    column.label == "SLA"
                                        ? "pointer"
                                        : "default",
                            }}
                            onClick={() => handleSort(column.name, column.label)}
                        >
                            <div className="mx-1 flex h-20 items-center justify-center rounded-2xl bg-vidalink-gray-400 px-2 py-4 text-lg font-bold text-vidalink-gray-700 print:bg-transparent print:p-0 print:text-lg">
                                {column.label}
                            </div>
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
}

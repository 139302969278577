export function Expired(props: any) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect width={24} height={24} rx={12} fill="#424242" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 5.75a6.25 6.25 0 100 12.5 6.25 6.25 0 000-12.5zM4.25 12a7.75 7.75 0 1115.5 0 7.75 7.75 0 01-15.5 0z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.999 8a.75.75 0 01.75.75V12l2.538 1.058a.75.75 0 01-.576 1.384l-3-1.25a.75.75 0 01-.462-.692V8.75a.75.75 0 01.75-.75z"
                fill="#fff"
            />
        </svg>
    );
}

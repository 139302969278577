import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "store";

interface LoaderState {
    isLoading: boolean;
    isImageLoading: boolean;
}

const initialState: LoaderState = {
    isLoading: false,
    isImageLoading: false,
};

export const loaderSlice = createSlice({
    name: "loader",
    initialState,
    reducers: {
        setLoadingOn: (state) => {
            state.isLoading = true;
        },
        setLoadingOff: (state) => {
            state.isLoading = false;
        },
        setImageLoadingOn: (state) => {
            state.isImageLoading = true;
        },
        setImageLoadingOff: (state) => {
            state.isImageLoading = false;
        },
    },
});

export const { setLoadingOff, setLoadingOn, setImageLoadingOff, setImageLoadingOn } =
    loaderSlice.actions;

export const selectLoader = (state: RootState) => state.login;

export default loaderSlice.reducer;

import { createBrowserRouter } from "react-router-dom";

import { Login } from "pages/Login";
import { PrescriptionList } from "pages/PrescriptionList";
import { PrescriptionDetail } from "pages/PrescriptionDetail";

export const router = createBrowserRouter([
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/",
        element: <PrescriptionList />,
    },
    {
        path: "/prescription/authorization/:objectId",
        element: <PrescriptionDetail />,
    },
]);

function Next() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="16"
            fill="none"
            viewBox="0 0 9 16"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M.633 1.339a1.37 1.37 0 011.936 0l5.81 5.808a1.37 1.37 0 010 1.937l-5.81 5.808a1.369 1.369 0 11-1.936-1.936l4.84-4.84-4.84-4.841a1.37 1.37 0 010-1.936z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Next;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";

interface LoginState {
    userAuthenticated: Boolean;
    accessToken: string;
    username: string;
}

const initialState: LoginState = {
    accessToken: "",
    userAuthenticated: false,
    username: "",
};

export const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
            state.userAuthenticated = true;

            localStorage.setItem("accessToken", action.payload);
            localStorage.setItem("userAuthenticated", "true");
        },
        setUser: (state, action: PayloadAction<string>) => {
            state.username = action.payload;

            localStorage.setItem("Username", action.payload);
        },
        logout: (state) => {
            state.accessToken = initialState.accessToken;
            state.userAuthenticated = initialState.userAuthenticated;

            localStorage.clear();
        },
    },
});

export const { logout, setToken, setUser } = loginSlice.actions;

export const selectLogin = (state: RootState) => state.login;

export default loginSlice.reducer;

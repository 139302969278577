import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import LoginReducer from "./modules/login";
import LoaderReducer from "./modules/loader";
import PrescriptionsReducer from "./modules/prescriptions";
import PrescriptionDetailReducer from "./modules/prescriptionDetail";

const persistConfig = {
    key: "root",
    storage,
};

const rootReducer = combineReducers({
    login: LoginReducer,
    loader: LoaderReducer,
    prescriptions: PrescriptionsReducer,
    prescriptionDetail: PrescriptionDetailReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["persist/PERSIST"],
            },
        }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import "./style.css";

export function Loader() {
    return (
        <div
            data-testid="loader"
            className="fixed top-0 left-0 flex h-full w-full cursor-wait items-center justify-center bg-vidalink-gray-700 bg-opacity-40"
        >
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

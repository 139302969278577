import axios from "axios";

const BASE_URL = process.env.REACT_APP_HACHIMON_URL;

const SERVICE_NAME = process.env.REACT_APP_PRODUCT_SERVICE_NAME;

const api = axios.create({
    baseURL: BASE_URL,
});

const VIDALINK_SESSION_ID = process.env.REACT_APP_VIDALINK_SESSION_ID;

api.interceptors.request.use((config) => {
    try {
        const token = localStorage.getItem("accessToken");

        if (config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers["Vidalink-Session-Id"] = VIDALINK_SESSION_ID;
        }

        return config;
    } catch (err) {
        throw Error("No token");
    }
});

export function getProductInformation({ customerId, cardNumber, search, isGeneric = false, substanceActive, from = 1, limit = 10 }) {
    return api.get(
        `${BASE_URL}/v1.0/${SERVICE_NAME}`, { params: { customerId, cardNumber, search, isGeneric, substanceActive, from, limit } }
    );
}

export function getProductsWithoutPlanFilters({ search, from, limit }) {
    return api.get(
        `${BASE_URL}/v2.0/${SERVICE_NAME}?search=${search}&from=${from}&limit=${limit}`
    );
}

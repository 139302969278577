export function Approved(props: any) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect width={24} height={24} rx={12} fill="#3BA648" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.718 7.304a1 1 0 01-.022 1.414l-8.25 8a1 1 0 01-1.392 0l-3.75-3.636a1 1 0 111.392-1.436l3.054 2.961 7.554-7.325a1 1 0 011.414.022z"
                fill="#fff"
            />
        </svg>
    );
}

export function Plus(props: any) {
    return (
        <svg width={36} height={36} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width={36} height={36} rx={18} fill="#fff" />
            <path
                d="M15.77 26.085V11.37h4.455v14.716H15.77Zm-5.13-5.13V16.5h14.715v4.454H10.64Z"
                fill="#377CB9"
            />
        </svg>
    );
}

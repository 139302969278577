function ArrowLeft() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="42"
            fill="none"
            viewBox="0 0 16 42"
        >
            <path
                fill="#377CB9"
                fillRule="evenodd"
                d="M.336 22.11a2 2 0 010-2.219l12-18.006a2 2 0 013.328 2.219L4.404 21l11.26 16.897a2 2 0 01-3.328 2.218l-12-18.006z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default ArrowLeft;

import axios from "axios";

const hachimon = process.env.REACT_APP_HACHIMON_AUTH_URL;
const hachimonKey = process.env.REACT_APP_HACHIMON_KEY;
const serviceName = process.env.REACT_APP_PRESCRIPTION_SERVICE_NAME;
export function loginAuthentication(user, password) {
    return axios.post(
        hachimon,
        { login: user, password: password },
        {
            headers: {
                service_name: serviceName,
                hashKey: hachimonKey,
            },
        }
    );
}

import { Fragment } from "react";

import { RouterProvider } from "react-router-dom";
import { router } from "router";

import { Loader } from "components/widgets/molecules/Loader";
import { useAppSelector } from "hooks/store";

export default function App() {
    const { isLoading } = useAppSelector((state) => state.loader);

    return (
        <Fragment>
            <RouterProvider router={router} />
            {isLoading && <Loader />}
        </Fragment>
    );
}

type Props = {
    label: string;
    active?: boolean;
    select: () => void;
};

export function PrescriptionsNavbarItem({ label, active = false, select }: Props) {
    return (
        <li
            className={`w-56 cursor-pointer rounded-full p-3 text-center text-xl font-bold transition-colors ${
                active ? "bg-white  text-vidalink-blue" : "text-white"
            }`}
        >
            <button className="h-full w-full" onClick={select}>
                {label}
            </button>
        </li>
    );
}

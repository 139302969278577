import axios from "axios";

const API_USER_URL = process.env.REACT_APP_API_USER_URL;

const api = axios.create({
    baseURL: API_USER_URL,
});

const VIDALINK_SESSION_ID = process.env.REACT_APP_VIDALINK_SESSION_ID;

api.interceptors.request.use((config) => {
    try {
        const token = localStorage.getItem("accessToken");

        if (config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers["Vidalink-Session-Id"] = VIDALINK_SESSION_ID;
        }

        return config;
    } catch (err) {
        throw Error("No token");
    }
});

export function getUserBySocialId(id: string) {
    return api.get(`${API_USER_URL}?socialId=${id}`);
}

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";
import { PrescriptionType } from "utils/enum/prescription";

interface PrescriptionState {
    list: Array<string>;
    type: PrescriptionType;
    hiddenColumns: Array<string>;
}

const initialState: PrescriptionState = {
    list: [],
    type: PrescriptionType.Pendentes,
    hiddenColumns: [],
};

export const PrescriptionSlice = createSlice({
    name: "prescription",
    initialState,
    reducers: {
        setPrescriptionList: (state, action: PayloadAction<String>) => {
            state.list = [];
        },
        setStatus: (state, action: PayloadAction<PrescriptionType>) => {
            state.type = action.payload;
        },
        setHiddenColumns: (state, action: PayloadAction<{ column: string; type: string }>) => {
            const { column, type } = action.payload;
            if (type === "remove") {
                state.hiddenColumns.push(column);
            }
            if (type === "add") {
                state.hiddenColumns = state.hiddenColumns.filter((col) => col !== column);
            }
        },
    },
});

export const { setPrescriptionList, setStatus, setHiddenColumns } = PrescriptionSlice.actions;

export const selectPrescription = (state: RootState) => state.prescriptions;

export default PrescriptionSlice.reducer;

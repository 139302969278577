const AlertCircleIcon = (props: any) => {
    return (
        <svg
            width="100"
            height="100"
            viewBox="0 0 74 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M37 0.921143C27.3196 0.921143 18.0357 4.76667 11.1906 11.6117C4.34552 18.4568 0.5 27.7407 0.5 37.4211C0.5 47.1015 4.34552 56.3855 11.1906 63.2305C18.0357 70.0756 27.3196 73.9211 37 73.9211C46.6804 73.9211 55.9643 70.0756 62.8094 63.2305C69.6545 56.3855 73.5 47.1015 73.5 37.4211C73.5 27.7407 69.6545 18.4568 62.8094 11.6117C55.9643 4.76667 46.6804 0.921143 37 0.921143ZM36.998 20.0012C37.6621 20.0012 38.2129 20.0364 38.6445 20.1028C39.0762 20.1526 39.415 20.2346 39.6641 20.3508C39.9297 20.4504 40.1113 20.5833 40.2109 20.7493C40.3271 20.8987 40.3848 21.0823 40.3848 21.2981L39.7402 43.9094C39.7236 44.0754 39.6709 44.2258 39.5879 44.3586C39.5049 44.4915 39.3584 44.6067 39.1426 44.7063C38.9434 44.7893 38.6582 44.8557 38.293 44.9055C37.9443 44.9553 37.5127 44.9797 36.998 44.9797C36.4668 44.9797 36.0283 44.9553 35.6797 44.9055C35.3311 44.8557 35.0498 44.7893 34.834 44.7063C34.6348 44.6067 34.4932 44.4915 34.4102 44.3586C34.3271 44.2258 34.2764 44.0754 34.2598 43.9094L33.6133 21.2981C33.6133 21.0823 33.6621 20.8918 33.7617 20.7258C33.8613 20.5598 34.0381 20.427 34.2871 20.3274C34.5527 20.2112 34.9004 20.1272 35.332 20.0774C35.7803 20.0276 36.334 20.0012 36.998 20.0012ZM36.998 47.4934C37.6787 47.4934 38.2451 47.5598 38.6934 47.6926C39.1416 47.8088 39.498 48.0168 39.7637 48.3157C40.0459 48.5979 40.2363 48.9788 40.3359 49.4602C40.4521 49.925 40.5098 50.5071 40.5098 51.2043C40.5098 51.885 40.4521 52.4583 40.3359 52.9231C40.2363 53.3879 40.0459 53.76 39.7637 54.0422C39.498 54.3245 39.1416 54.5237 38.6934 54.6399C38.2451 54.7727 37.6787 54.8391 36.998 54.8391C36.3174 54.8391 35.748 54.7727 35.2832 54.6399C34.835 54.5237 34.4766 54.3245 34.2109 54.0422C33.9453 53.76 33.7529 53.3879 33.6367 52.9231C33.5371 52.4583 33.4902 51.885 33.4902 51.2043C33.4902 50.5071 33.5371 49.925 33.6367 49.4602C33.7529 48.9788 33.9453 48.5979 34.2109 48.3157C34.4766 48.0168 34.835 47.8088 35.2832 47.6926C35.748 47.5598 36.3174 47.4934 36.998 47.4934Z"
                fill="#F79831"
            />
        </svg>
    );
};
export default AlertCircleIcon;
export function Expand(props: any) {
    return (
        <svg
            width={34}
            height={34}
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.833 4.25c0-.782.635-1.417 1.417-1.417h8.5c.782 0 1.417.635 1.417 1.417v8.5a1.417 1.417 0 01-2.834 0V5.667H21.25a1.417 1.417 0 01-1.417-1.417zM4.25 19.833c.782 0 1.417.635 1.417 1.417v7.083h7.083a1.417 1.417 0 010 2.834h-8.5a1.417 1.417 0 01-1.417-1.417v-8.5c0-.782.635-1.417 1.417-1.417z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.752 3.248c.553.553.553 1.45 0 2.004l-9.917 9.916a1.417 1.417 0 01-2.003-2.003l9.916-9.917a1.417 1.417 0 012.004 0zM15.168 18.832c.554.553.554 1.45 0 2.003l-9.916 9.917a1.417 1.417 0 01-2.004-2.004l9.917-9.916a1.417 1.417 0 012.003 0z"
                fill="#fff"
            />
        </svg>
    );
}

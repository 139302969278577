import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Success } from "assets/icons/Success";

export enum MessageType {
    VALIDATION = "validation",
    REJECTION = "rejection",
}

type PopupProps = {
    type: MessageType;
    isOpen: boolean;
    close: () => void;
};

export function SuccessPopup({ isOpen, close, type }: PopupProps) {
    const [text, setText] = useState("");

    useEffect(() => {
        switch (type) {
            case "rejection":
                setText("Receita reprovada com sucesso!");
                return;
            case "validation":
                setText("Receita validada com sucesso!");
                return;
        }
    }, [type]);

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog open={isOpen} onClose={close} className="relative z-50 flex justify-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div
                        data-testid="success-popup"
                        className="fixed inset-0 m-auto flex h-[409px] w-[732px] flex-col items-center justify-between rounded-lg bg-white p-4 py-20"
                    >
                        <Dialog.Panel className="flex h-full flex-col items-center justify-between rounded bg-white">
                            <Success />

                            <p className="text-center text-[2rem] font-bold text-vidalink-blue">
                                {text}
                            </p>
                        </Dialog.Panel>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition>
    );
}

export function formatTime(timeInSeconds: number) {
    const isNegative = timeInSeconds < 0;
    const amountOfSeconds = Math.abs(timeInSeconds);

    const hours = Math.floor(amountOfSeconds / 3600);
    const minutes = Math.floor((amountOfSeconds - hours * 3600) / 60);
    const seconds = amountOfSeconds % 60;

    const padding = (x: number) => parseInt(x.toString(), 10).toString().padStart(2, "0");

    return `${isNegative ? "-" : ""}${padding(hours)}:${padding(minutes)}:${padding(seconds)}`;
}

import { PrescriptionsNavbarItem } from "components/widgets/atoms/PrescriptionsNavbarItem";
import { PrescriptionType } from "utils/enum/prescription";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { setStatus } from "store/modules/prescriptions";
import usePrescriptionStore from "store/modules/filterPrescriptions";
import { useEffect, useState } from "react";
import { getAllPrescriptions } from "services/api/prescription/prescriptionService";

export function PrescriptionsNavbar() {
    const { setFilterPrescriptions, filterPrescriptions } = usePrescriptionStore();

    const [prescriptionStatus, setPrescriptionStatus] = useState("Pendentes");

    function handleSelectType(type: PrescriptionType) {
        setFilterPrescriptions({ prescriptionStatus: type });
        setPrescriptionStatus(type);
    }

    return (
        <nav className="bg-vidalink-blue py-6 print:hidden">
            <ul className="container mx-auto flex items-center space-x-14">
                <PrescriptionsNavbarItem
                    label="Pendentes"
                    active={prescriptionStatus === PrescriptionType.Pendentes}
                    select={() => handleSelectType(PrescriptionType.Pendentes)}
                />
                <PrescriptionsNavbarItem
                    label="Reprovadas"
                    active={prescriptionStatus === PrescriptionType.Reprovadas}
                    select={() => handleSelectType(PrescriptionType.Reprovadas)}
                />
                <PrescriptionsNavbarItem
                    label="Validadas"
                    active={prescriptionStatus === PrescriptionType.Validadas}
                    select={() => handleSelectType(PrescriptionType.Validadas)}
                />
                <PrescriptionsNavbarItem
                    label="Todas"
                    active={prescriptionStatus === PrescriptionType.Todas}
                    select={() => handleSelectType(PrescriptionType.Todas)}
                />
            </ul>
        </nav>
    );
}

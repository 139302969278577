function ArrowRight() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="42"
            fill="none"
            viewBox="0 0 16 42"
        >
            <path
                fill="#377CB9"
                fillRule="evenodd"
                d="M15.664 19.89a2 2 0 010 2.219l-12 18.005a2 2 0 11-3.328-2.218L11.596 21 .337 4.104a2 2 0 113.328-2.219l12 18.006z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default ArrowRight;

import { useEffect, useState } from "react";
import { TransformWrapper, TransformComponent } from "@pronestor/react-zoom-pan-pinch";
import { Plus } from "assets/icons/Plus";
import { Minus } from "assets/icons/Minus";
import { Rotate } from "assets/icons/Rotate";
import { Expand } from "assets/icons/Expand";

import Lightbox from "react-image-lightbox-rotation";
import "react-image-lightbox/style.css";
import { useAppSelector } from "hooks/store";
import Previous from "assets/icons/Previous";
import Next from "assets/icons/Next";

type ImageGalleryProps = {
    images: Array<string>;
};

export function ImageGallery({ images }: ImageGalleryProps) {
    const isImageLoading = useAppSelector((state) => state.loader.isImageLoading);

    const [degrees, setDegrees] = useState(0);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const [currentImage, setCurrentImage] = useState<string>();

    useEffect(() => {
        setCurrentImage(images[currentImageIndex]);
    }, [currentImageIndex, images]);

    function goToPreviousImage() {
        if (currentImageIndex <= 0) return;
        setCurrentImageIndex((state) => state - 1);
    }

    function goToNextImage() {
        if (currentImageIndex >= images.length - 1) return;
        setCurrentImageIndex((state) => state + 1);
    }

    function handleRotate() {
        setDegrees((degrees) => (degrees + 90) % 360);
    }

    function handleMouseScroll({ state, setTransform }) {
        const { previousScale, scale, positionX, positionY } = state;

        const isZoomingIn = scale > previousScale;

        let newScale = 1;

        if (isZoomingIn) {
            if (scale > 1) {
                newScale = 1.5;
            }
            if (scale > 1.5) {
                newScale = 2;
            }
        } else {
            if (scale < 2) {
                newScale = 1.5;
            }
            if (scale < 1.5) {
                newScale = 1;
            }
        }

        setTransform(positionX, positionY, newScale);
    }

    return (
        <div
            data-testid="image-gallery"
            className="flex h-full w-full min-w-full flex-col items-center justify-center "
        >
            <TransformWrapper maxScale={2} minScale={1} onWheel={handleMouseScroll}>
                {({ state, centerView, ...rest }) => {
                    const { scale } = state;

                    const percentage = Math.round(scale * 100);

                    function handleMinusZoom() {
                        if (scale <= 1) return;

                        centerView(scale - 0.5);
                    }

                    function handlePlusZoom() {
                        if (scale >= 2) return;

                        centerView(scale + 0.5);
                    }

                    return (
                        <div className="flex h-full min-w-[80%] flex-col overflow-hidden rounded-lg border-2 border-vidalink-blue">
                            {isImageLoading && (
                                <div
                                    data-testid="loader"
                                    className=" top-0 left-0 flex h-full w-full cursor-wait items-center justify-center bg-vidalink-gray-700 bg-opacity-40"
                                >
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            )}
                            <div className="relative h-full w-full flex-1">
                                <TransformComponent>
                                    {currentImage?.includes("pdf") ? (
                                        <iframe
                                            className="h-full w-full"
                                            src={currentImage}
                                            title="PDF"
                                        ></iframe>
                                    ) : (
                                        <img
                                            src={currentImage || images[0]}
                                            alt=""
                                            style={{
                                                width: "100%",
                                                objectFit: "contain",
                                                transform: `rotate(${degrees}deg)`,
                                            }}
                                        />
                                    )}
                                </TransformComponent>
                            </div>
                            <div className="flex bg-vidalink-blue py-5 px-2 text-white lg:px-8">
                                <div className="flex w-full items-center justify-between">
                                    <div className="flex items-center gap-2 2xl:gap-8">
                                        <button data-testid="" onClick={handleRotate}>
                                            <Rotate />
                                        </button>
                                        <button
                                            data-testid="btn-expand"
                                            type="button"
                                            className="w-full"
                                            onClick={() => setOpen(true)}
                                        >
                                            <Expand />
                                        </button>
                                    </div>
                                    <div className="flex items-center gap-3 font-bold">
                                        <button onClick={goToPreviousImage} className="p-2">
                                            <Previous />
                                        </button>
                                        Imagem {images.length > 0 ? currentImageIndex + 1 : 0} de{" "}
                                        {images.length}
                                        <button onClick={goToNextImage} className="p-2">
                                            <Next />
                                        </button>
                                    </div>
                                    <div className="flex w-32 items-center justify-between xl:w-36">
                                        <button data-testid="btn-minus" onClick={handleMinusZoom}>
                                            <Minus />
                                        </button>
                                        <span className="font-bold">{percentage}%</span>
                                        <button
                                            data-testid="btn-plus"
                                            className="btn btn-outline-secondary"
                                            onClick={handlePlusZoom}
                                        >
                                            <Plus />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </TransformWrapper>

            {isOpen && (
                <Lightbox
                    mainSrc={currentImage || images[0]}
                    onCloseRequest={() => setOpen(false)}
                    rotate={degrees}
                />
            )}
        </div>
    );
}

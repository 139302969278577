import { Clipboard } from "assets/icons/Clipboard";

export function Jumbotron() {
    return (
        <div className="flex h-[25vh] min-h-[176px] flex-col items-center justify-center gap-4 bg-vidalink-blue py-6">
            <div className="flex h-20 w-20 items-center justify-center rounded-full bg-white">
                <Clipboard className="h-12 w-12" />
            </div>
            <h2 className="text-2xl font-extrabold text-white">Verificador de receitas Vidalink</h2>
        </div>
    );
}

import { useForm, SubmitHandler } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
import { useAppDispatch } from "hooks/store";

import { setToken, setUser } from "store/modules/login";
import { loginAuthentication } from "services/api/auth/authService";
import { Toast, ToastType } from "components/widgets/atoms/Toast";
import { setLoadingOff, setLoadingOn } from "store/modules/loader";

type FormInputs = {
    username: string;
    password: string;
};

export function Form() {
    const dispatch = useAppDispatch();

    const { register, handleSubmit } = useForm<FormInputs>();

    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
        const { password, username } = data;

        if (!username) {
            return toast.custom(
                (t) => <Toast t={t} text="Usuário é obrigatório" type={ToastType.Error} />,
                { id: "username-missing" }
            );
        }

        if (!password) {
            return toast.custom(
                (t) => <Toast t={t} text="Senha é obrigatória" type={ToastType.Error} />,
                { id: "password-missing" }
            );
        }

        dispatch(setLoadingOn());

        try {
            const result = await loginAuthentication(username, password);
            const { access_token } = result.data;

            dispatch(setToken(access_token));
            dispatch(setUser(username));
        } catch (err) {
            if (err instanceof Error) {
                const { message } = err;
                const unauthorized = message.includes("401");

                if (unauthorized) {
                    return toast.custom(
                        (t) => (
                            <Toast t={t} text="Login ou senha inválidos" type={ToastType.Warning} />
                        ),
                        { id: "invalid" }
                    );
                }
            }
        } finally {
            dispatch(setLoadingOff());
        }
    };

    function handleForgetPassword() {
        return toast.custom(
            (t) => (
                <Toast
                    t={t}
                    text="A senha para acessar o sistema é a mesma senha do Gerenciador Vidalink."
                    type={ToastType.Warning}
                />
            ),
            { id: "forget-password" }
        );
    }

    return (
        <form
            name="login-form"
            className="w-full max-w-[600px] space-y-10"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-control">
                <label htmlFor="user">Usuário</label>
                <input
                    data-testid="input-user"
                    type="text"
                    id="user"
                    placeholder="Digite seu usuário aqui"
                    {...register("username")}
                />
            </div>

            <div className="form-control">
                <label htmlFor="password">Senha</label>
                <input
                    type="password"
                    data-testid="input-password"
                    id="password"
                    placeholder="Digite sua senha aqui"
                    className="h-[52px] text-[32px] placeholder:mb-4 placeholder:-translate-y-1 placeholder:text-xl"
                    {...register("password")}
                />
                <button
                    type="button"
                    data-testid="btn-forget-password"
                    onClick={handleForgetPassword}
                    className="ml-auto cursor-pointer rounded text-xl font-bold text-vidalink-blue outline-none ring-vidalink-blue hover:underline focus:ring-2"
                >
                    esqueci a minha senha
                </button>
            </div>

            <Toaster
                data-testid="snackbar"
                position="bottom-center"
                containerStyle={{
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
                toastOptions={{
                    duration: 3000,
                }}
            />

            <button
                type="submit"
                data-testid="btn-login"
                className="w-64 cursor-pointer rounded-3xl bg-vidalink-blue p-3 text-xl font-bold text-white transition hover:opacity-90"
            >
                Acessar
            </button>
        </form>
    );
}

import { useEffect } from "react";
import { PublicPage } from "components/layouts/PublicPage";
import { Form } from "components/views/Login/Form";
import { Jumbotron } from "components/views/Login/Jumbotron";
import { useAppDispatch } from "hooks/store";
import { setLoadingOff } from "store/modules/loader";
import SignIn from "assets/images/signin.png";

export function Login() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setLoadingOff());
    }, [dispatch]);

    return (
        <PublicPage>
            <Jumbotron />
            <section className="mb-4 flex flex-1 items-center px-5">
                <div className="container mx-auto mt-10 flex h-[50vh] min-h-[346px] flex-col items-center justify-between gap-4 lg:flex-row">
                    <Form />
                    <div className="mx-auto h-[40vh] w-auto">
                        <img src={SignIn} alt="Sign in" className="mx-auto h-full" />
                    </div>
                </div>
            </section>
        </PublicPage>
    );
}

import { Product } from "types/Product";
import axios from "axios";
import { PrescriptionDetails } from "utils/paginationPrescriptionType";

import usePrescriptionsFilter from "../../../store/modules/filterPrescriptions";

const BASE_URL = process.env.REACT_APP_API_PRESCRIPTION_URL;

const { setFilterPrescriptions, filterPrescriptions } = usePrescriptionsFilter.getState();

const api = axios.create({
    baseURL: BASE_URL,
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            sessionStorage.clear();
            localStorage.clear();

            return (window.location.href = "/login");
        }

        return Promise.reject(error);
    }
);

export enum PrescriptionStatus {
    analisypending = "AnalisyPending",
    reproved = "Reproved",
    approved = "Approved",
    all = "",
}

const VIDALINK_SESSION_ID = process.env.REACT_APP_VIDALINK_SESSION_ID;

api.interceptors.request.use((config) => {
    try {
        const token = localStorage.getItem("accessToken");

        if (config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers["Vidalink-Session-Id"] = VIDALINK_SESSION_ID;
        }

        return config;
    } catch (err) {
        throw Error("No token");
    }
});

export const getAllPrescriptions = async (prescription: PrescriptionDetails) => {
    const { data } = await api.post(
        `${BASE_URL}/prescription/previous-shipment/filter-iterations`,
        { ...prescription }
    );

    if (data) {
        setFilterPrescriptions({
            pagination: {
                totalPages: data?.pagination?.totalPages,
            },
        });
    }

    return data;
};

export function getPrescriptionImage(imagesUrl: string[]) {
    let qs = "";
    if (Array.isArray(imagesUrl)) {
        imagesUrl.map((url) => (qs += `fileName=${url}&`));
    }

    return api.get(`${BASE_URL}/prescription/Previous-Shipment/iteration/file?${qs}`);
}

export function searchAllPrescriptions(
    prescriptionStatus: PrescriptionStatus,
    textToSearch: string
) {
    return api.get(
        `${BASE_URL}/prescription/Previous-Shipment/Iteration/Find/${prescriptionStatus}?textToSearch=${textToSearch}`
    );
}

export function getOnePrescription(objectId: string) {
    return api.get(`${BASE_URL}/prescription/Previous-Shipment/iteration?ObjectId=${objectId}`);
}

export type UpdatePrescriptionBodyType = {
    objectId: string;
    status: {
        reprovalInformation?: {
            IdReprovalReasons: Array<number>;
            reprovalObservation?: string;
        };
        status: "Approved" | "Reproved";
    };
    regionalCouncil: number;
    regionalCouncilType: number;
    stateRegionalCouncil: string;
    products: Product[];
    prescriptionDate: string;
    username: string;
    socialId: string;
    cardNumber: string;
    startAnalyseDateTime: string;
    clientId: string;
};

export function updatePrescription(updatePrescriptionRequest: UpdatePrescriptionBodyType) {
    return api.patch(
        `${BASE_URL}/prescription/Previous-Shipment/Iteration`,
        updatePrescriptionRequest
    );
}

type ValidateProductBody = {
    clientGroup: string;
    clientId: string;
    customerId: string;
    ean: string;
    prescriptionDate: string; //"2023-01-01";
    regionalCouncilNumber: number;
    stateRegionalCouncil: string;
    productStatus: "Approved" | "Reproved";
    cardNumber: string;
};

export function validatePrescriptionProduct(body: ValidateProductBody) {
    return api.post(`${BASE_URL}/Prescription/product`, body);
}

export enum AnalyzeStatus {
    InAnalysis = "InAnalysis",
    Analysed = "Analysed",
}

type AnalysesStatusType = {
    Username: string;
    ObjectId: string;
    Status: AnalyzeStatus;
};

export function setAnalysesStatus(setAnalysesStatusRequest: AnalysesStatusType) {
    return api.patch(
        `${BASE_URL}/prescription/Previous-Shipment/Iteration/analysestatushistory`,
        setAnalysesStatusRequest
    );
}

export function getReprovalReasons() {
    return api.get(`${BASE_URL}/prescription/Previous-Shipment/iteration/reprovalreasons`);
}

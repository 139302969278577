export function Rotate() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            fill="none"
            viewBox="0 0 34 34"
        >
            <g
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4"
                clipPath="url(#clip0_1188_1239)"
            >
                <path d="M32.584 5.667v8.5h-8.5"></path>
                <path d="M29.028 21.25a12.75 12.75 0 11-3.004-13.26l6.56 6.177"></path>
            </g>
            <defs>
                <clipPath id="clip0_1188_1239">
                    <path fill="#fff" d="M0 0H34V34H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

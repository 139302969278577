import React, { ChangeEvent, useEffect, useState } from "react";
import { uniqBy } from "lodash";
import { Card } from "components/widgets/organisms/Card";
import {
    isPrescriptionPending,
    setReprovalReasonDescription,
    setReprovalReasons,
} from "store/modules/prescriptionDetail";
import { useAppDispatch, useAppSelector } from "hooks/store";
import Trash from "assets/icons/Trash";

type Reason = {
    id: string;
    label: string;
};

type Props = {
    reasonsData: Array<Reason>;
};

export function RejectRecipe({ reasonsData }: Props) {
    const dispatch = useAppDispatch();
    const isPending = useAppSelector(isPrescriptionPending);
    const reprovalInformation = useAppSelector(
        (state) => state.prescriptionDetail.data.status.reprovalInformation
    );

    const [reprovalObservation, setReprovalObservation] = useState("");

    const [selectedReasons, setSelectedReasons] = useState<Reason[]>([]);

    useEffect(() => {
        if (reprovalInformation) {
            const {
                reprovalObservation,
                idReprovalReason,
                idReprovalReasons,
                reprovalReasonDescription,
                reprovalReasonsDescriptions,
            } = reprovalInformation;

            setReprovalObservation(reprovalObservation);

            const reasons: Reason[] = [];

            if (idReprovalReason && reprovalReasonDescription) {
                reasons.push({
                    id: idReprovalReason.toString(),
                    label: reprovalReasonDescription,
                });
            }

            for (let i = 0; i < idReprovalReasons.length; i++) {
                reasons.push({
                    id: idReprovalReasons[i].toString(),
                    label: reprovalReasonsDescriptions[i],
                });
            }

            const noDuplicatesReasons = uniqBy(reasons, "id");

            setSelectedReasons(noDuplicatesReasons);
        }
    }, [reprovalInformation]);

    const reasons = Object.entries(reasonsData).map((reason) => {
        const [id, label] = reason;

        return {
            id,
            label: label?.toString(),
        };
    });

    function handleSelectReason(event: React.ChangeEvent<HTMLSelectElement>) {
        const { value } = event.target;
        const text =
            event.target.querySelector<HTMLOptionElement>(`option[value="${value}"]`)?.text || "";

        const newReason: Reason = {
            id: value,
            label: text,
        };

        event.target.value = "-1"; // reset input

        const reasonAlreadySelected = selectedReasons.some((reason) => reason.id === newReason.id);

        if (reasonAlreadySelected) return;

        setSelectedReasons([...selectedReasons, newReason]);
    }

    function handleRemoveReason(id: string) {
        setSelectedReasons((reasons) => reasons.filter((reason) => reason.id !== id));
    }

    function handleUpdateObservations(event: ChangeEvent<HTMLTextAreaElement>) {
        const { value } = event.target;

        setReprovalObservation(value);
        dispatch(setReprovalReasonDescription(value));
    }

    useEffect(() => {
        const reasonsIds = selectedReasons.map((reason) => reason.id);
        dispatch(setReprovalReasons(reasonsIds));
    }, [dispatch, selectedReasons]);

    return (
        <Card>
            <h3 className="mb-6 text-[2rem] font-bold text-vidalink-blue">Reprovar receita</h3>

            <div className="form-control mb-6">
                <label htmlFor="reason">Motivo de reprova</label>
                <select
                    name=""
                    id="reason"
                    onChange={handleSelectReason}
                    disabled={!isPending}
                    className="text-vidalink-gray-400"
                    defaultValue={"-1"}
                >
                    <option disabled hidden value="-1">
                        Insira o motivo da reprova
                    </option>
                    {reasons.map((reason) => (
                        <option className="text-black" key={reason.id} value={reason.id}>
                            {reason.label}
                        </option>
                    ))}
                </select>
            </div>

            <div className="mb-6 space-y-3">
                {selectedReasons.map((reason, index) => (
                    <div key={reason.id} className="flex gap-4">
                        <div className="form-control flex-1">
                            <input type="text" disabled value={`${index + 1}. ${reason.label}`} />
                        </div>

                        <button
                            onClick={() => handleRemoveReason(reason.id)}
                            disabled={!isPending}
                            className="flex h-12 w-12 items-center justify-center rounded-full bg-vidalink-magenta text-white"
                        >
                            <Trash />
                        </button>
                    </div>
                ))}
            </div>

            <div className="form-control mb-11">
                <label htmlFor="observations">Observações</label>
                <textarea
                    className="h-40 resize-none placeholder:text-vidalink-gray-400"
                    id="observations"
                    placeholder="Descreva o motivo da reprova"
                    value={reprovalObservation}
                    disabled={!isPending}
                    onChange={handleUpdateObservations}
                ></textarea>
            </div>
        </Card>
    );
}

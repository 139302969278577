import { Approved } from "assets/icons/Approved";
import { Expired } from "assets/icons/Expired";
import { Reproved } from "assets/icons/Reproved";
import { useEffect } from "react";

type MedicationStatusProps = {
    status: any;
    validDate: Boolean;
};

export function MedicationStatus({ status, validDate }: MedicationStatusProps) {
    function getLabelColor() {
        if (status === "Approved" && !validDate) {
            return "#424242";
        }

        switch (status) {
            case "Reproved":
                return "#CF2860";
            case "Approved":
                return "#3BA648";
        }
    }

    function getLabelText() {
        if (status === "Approved" && !validDate) {
            return "Medicamento vencido";
        }

        switch (status) {
            case "Reproved":
                return "Medicamento não subsidiado";
            case "Approved":
                return "Medicamento subsidiado";
        }
    }

    function getIconType() {
        if (status === "Approved" && !validDate) {
            return <Expired />;
        }

        switch (status) {
            case "Reproved":
                return <Reproved />;
            case "Approved":
                return <Approved />;
        }
    }

    return (
        <div
            data-testid="medication-status"
            className="absolute right-4 top-2 flex cursor-default bg-vidalink-gray-100 p-2"
        >
            {getIconType()}
            <span className="ml-2.5 text-base font-bold" style={{ color: getLabelColor() }}>
                {getLabelText()}
            </span>
        </div>
    );
}

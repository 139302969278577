import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { SLATimer } from "components/widgets/atoms/SLATimer";

type SLAProps = {
    expirationDate?: number;
    calcExpirationDate?: number;
    cardNumber: string;
    customerId: string;
    slaPreSend: number;
    slaTimer?: string;
    clockRunning?: boolean;
};

export function SLA({
    cardNumber,
    expirationDate,
    calcExpirationDate,
    customerId,
    slaPreSend,
    clockRunning,
    slaTimer,
}: SLAProps) {
    const [minutes, setMinutes] = useState(0);

    const calculateTime = useCallback(async () => {
        const now = moment(new Date()).toISOString();

        const end = moment(calcExpirationDate).toISOString();

        const differenceMilliseconds = moment(end).diff(moment(now));

        const duration = moment.duration(differenceMilliseconds);

        const durationInMinutes = duration.asMinutes();

        setMinutes(durationInMinutes);
    }, [cardNumber, expirationDate, customerId, clockRunning, calcExpirationDate, slaTimer]);

    useEffect(() => {
        calculateTime();
    }, [calculateTime]);

    return (
        <SLATimer
            currentMinutes={minutes}
            slaMinutes={slaPreSend}
            slaTimer={slaTimer as string}
            clockRunning={clockRunning as boolean}
        />
    );
}
